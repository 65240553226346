import axios from './axios'
//开箱记录
export function UnpackingRecord(data) {
	return axios({
		url: '/openhistory',
		params: data
	})
}

// Roll房列表
export function Rooms(page, status) {
	return axios({
		url: '/rooms',
		params: {
			page,
			status
		}
	})
}

//Roll房详情
export function RoomDetail(id) {
	return axios({
		url: '/room/detail',
		params: {
			id
		}
	})
}

//加入Roll房
export function RoomJoin(id, password) {
	return axios({
		url: '/room/join',
		method: 'post',
		data: {
			id,
			password
		}
	})
}


//热门宝箱
export function GetHotBox() {
	return axios({
		url: '/hotbox'
	})
}



//宝箱详情
export function BoxDetail(id) {
	return axios({
		url: '/box/detail',
		params: {
			id
		}
	})
}

//开箱
export function Open(id, num) {
	return axios({
		url: '/open',
		method: 'post',
		data: {
			id,
			num
		}
	})
}


//登录
export function SignIn(mobile, password, verify) {
	if (verify == '') {
		return axios({
			url: '/login',
			method: 'post',
			data: {
				mobile,
				password
			}
		})
	} else {
		return axios({
			url: '/sms_login',
			method: 'post',
			data: {
				mobile,
				verify
			}
		})
	}

}

//发送邮箱验证码
export function PostEmail(email, usage) {
	return axios({
		url: '/send_email',
		method: 'post',
		data: {
			email,
			usage
		}
	})
}
//发送手机验证码
export function PostSendSms(mobile, is_use) {
	return axios({
		url: '/send_sms',
		method: 'post',
		data: {
			mobile,
			is_use
		}
	})
}

//注册
export function Register(mobile, password, verify, invite) {
	return axios({
		url: '/register',
		method: 'post',
		data: {
			mobile,
			password,
			verify,
			invite
		}
	})
}

//个人信息
export function PersonalInfo() {
	return axios({
		url: '/me'
	})
}

//参加房间记录
export function UserRooms(page) {
	return axios({
		url: '/user/rooms',
		params: {
			page
		}
	})
}

//福利宝箱列表
export function Welfare() {
	return axios({
		url: '/welfare'
	})
}


//福利宝箱开箱
export function WelfareOpen(id, cdk) {
	return axios({
		url: '/welfare/open',
		method: 'post',
		data: {
			id,
			cdk
		}
	})
}

//饰品列表
export function GetShop(min_integral, max_integral, sort, page) {
	return axios({
		url: '/shop',
		params: {
			min_integral,
			max_integral,
			sort,
			page
		}
	})
}

//饰品购买
export function ShopBuy(id, num) {
	return axios({
		url: '/shop/exchange',
		method: 'post',
		data: {
			id,
			num
		}
	})
}

//幸运开箱类别
export function LuckyType() {
	return axios({
		url: '/lucky/type'
	})
}

//幸运开箱搜索
export function LuckySo(name) {
	return axios({
		url: '/lucky/so',
		params: {
			name
		}
	})
}

//幸运开箱物品列表
export function LuckyList(type_id) {
	return axios({
		url: '/lucky/list',
		params: {
			type_id
		}
	})
}

//装备查询
export function AwardGet(id) {
	return axios({
		url: '/award/get',
		params: {
			id
		}
	})
}

//幸运开箱(开箱)
export function LuckyOpen(id, percent) {
	return axios({
		url: '/lucky/open',
		method: 'post',
		data: {
			id,
			percent
		}
	})
}


//金豆列表
export function Beans() {
	return axios({
		url: '/beans'
	})
}

//支付
export function PostRecharge(id, pay_way) {
	return axios({
		url: '/recharge',
		method: 'post',
		data: {
			id,
			pay_way
		}
	})
}


//卡密兑换

export function CardRecharge(card) {
	return axios({
		url: '/card-recharge',
		method: 'post',
		data: {
			card
		}
	})
}

//充值记录
export function RechargeRecord(page) {
	return axios({
		url: '/recharge_record',
		params: {
			page
		}
	})
}

//Base64上传
export function ImageBase64(data) {
	return axios({
		url: '/image/base64',
		method: 'post',
		data: {
			data
		}
	})
}

//更新头像地址
export function SetAvatar(path) {
	return axios({
		url: '/set_avatar',
		method: 'post',
		data: {
			path
		}
	})
}

//修改setam链接
export function SetSteam_url(steam_url) {
	return axios({
		url: '/set_steam_url',
		method: 'post',
		data: {
			steam_url
		}
	})
}

//修改昵称
export function SetName(name) {
	return axios({
		url: '/set_name',
		method: 'post',
		data: {
			name
		}
	})
}

//修改推广码
export function SetInvite_code(code) {
	return axios({
		url: '/set_invite_code',
		method: 'post',
		data: {
			code
		}
	})
}

//修改推广码
export function set_inviter(invite_code) {
	return axios({
		url: '/set_inviter',
		method: 'post',
		data: {
			invite_code
		}
	})
}

//背包列表
export function Storage(params) {
	return axios({
		url: '/storage',
		params
	})
}

//兑换金币(回收)
export function Cash(data) {
	return axios({
		url: '/cash',
		method: 'post',
		data: {
			data
		}
	})
}

//物品提取(提货)
export function Extract(data) {
	return axios({
		url: '/extract',
		method: 'post',
		data: {
			data
		}
	})
}

//物品赠送
export function Give(id, invite_code) {
	return axios({
		url: '/give',
		method: 'post',
		data: {
			id,
			invite_code
		}
	})
}

//推广数据
export function Promote() {
	return axios({
		url: '/promote'
	})
}

//推广明细列表
export function Be_inviter(page) {
	return axios({
		url: '/be_inviter',
		params: {
			page
		}
	})
}

//更新/找回密码
export function Reset_password(mobile, password, verify) {
	return axios({
		url: '/reset_password',
		method: 'post',
		data: {
			mobile,
			password,
			verify
		}
	})
}

//站点信息
export function ApiInfo() {
	return axios({
		url: '/info'
	})
}


//箱子对战

//加入对战频道
export function Join_channel(client_id) {
	return axios({
		url: '/arena/join-channel',
		method: 'post',
		data: {
			client_id
		}
	})
}

//宝箱列表
export function ArenaBoxList() {
	return axios({
		url: '/arena/box-list'
	})
}

//创建对战
export function Arena_Create(data) {
	return axios({
		url: '/arena/create',
		method: 'post',
		data
	})
}

//对战列表
export function ArenaList(page) {
	return axios({
		url: '/arena/list',
		params: {
			page
		}
	})
}

//对战详情
export function ArenaDetail(game_arena_id) {
	return axios({
		url: '/arena/detail',
		params: {
			game_arena_id
		}
	})
}

//加入对战
export function ArenaJoin(game_arena_id, seat) {
	return axios({
		url: '/arena/join',
		method: 'post',
		data: {
			game_arena_id,
			seat
		}
	})
}

//邀请NPC
export function ArenaJoinNPC(game_arena_id, seat) {
	return axios({
		url: '/arena/inviteNpc',
		method: 'post',
		data: {
			game_arena_id,
			seat
		}
	})
}

//对战历史
export function ArenaHistory(page) {
	return axios({
		url: '/arena/history',
		params: {
			page
		}
	})
}

//我的历史记录
export function ArenaMeHistory(page) {
	return axios({
		url: '/arena/me-history',
		params: {
			page
		}
	})
}

//红包列表
export function Red() {
	return axios({
		url: '/red'
	})
}


//开启红包
export function Red_open(id) {
	return axios({
		url: '/red/open',
		method: 'post',
		data: {
			id,
		}
	})
}

//开启口令红包
export function Red_open_key(key) {
	return axios({
		url: '/red/open_key',
		method: 'post',
		data: {
			key
		}
	})
}

//开启口令红包
export function Red_luck_records(key) {
	return axios({
		url: '/red/luck_records',
		method: 'get',
		params: {
			key
		}
	})
}

//装备合成
export function Synthesis(data) {
	return axios({
		url: '/synthesis',
		method: 'post',
		data
	})
}

//合成装备列表
export function Synthesis_list(params) {
	return axios({
		url: '/synthesis/list',
		params
	})
}

//夺宝房间列表
export function Snatch(page, status) {
	return axios({
		url: '/snatch',
		params: {
			page,
			status
		}
	})
}

//加入夺宝房间
export function Snatch_Join(id, num) {
	return axios({
		url: '/snatch/join',
		method: 'post',
		data: {
			id,
			num
		}
	})
}

//夺宝详情
export function Snatch_Detail(id) {
	return axios({
		url: '/snatch/detail',
		params: {
			id
		}
	})
}

// vip收支明细
export function vipMe(page) {
	return axios({
		url: '/vip/me?page=' + page,
	})
}

//vip列表
export function getVipLists(page) {
	return axios({
		url: '/vip/list',
	})
}
// 审核中的roll列表
export function rollPending(page) {
	return axios({
		url: '/room/pending',
	})
}

//配置信息
export function getConfig() {
	return axios({
		url: '/get_config'
	})
}

//收益明细
export function userBenefit(page) {
	return axios({
		url: '/user/benefit',
		params: {
			page
		}
	})
}

//收益统计
export function userBenefitStat(page,type) {
	return axios({
		url: '/user/benefit_stat',
		params: {
			page,
			type
		}
	})
}

//热门活动
export function activityHot(params) {
	return axios({
		url: '/activity/hot',
		params
	})
}

//活动列表
export function activityList(params) {
	return axios({
		url: '/activity/list',
		params
	})
}

//活动信息
export function activityInfo(params) {
	return axios({
		url: '/activity/info',
		params
	})
}

//活动领取
export function activityFinish(params) {
	return axios({
		url: '/activity/finish',
		method: 'post',
		data: params
	})
}

//用户签到
export function userSign() {
	return axios({
		url: '/user/sign-in',
		method: 'post',
	})
}

//用户签到
export function hotReport(params) {
	return axios({
		url: '/activity/hotReport',
		method: 'post',
		data: params
	})
}


//双11
//活动数据
export function activity20231210Info(params) {
	return axios({
		url: '/activity20231210/info',
		params
	})
}

//任务领取
export function activity20231210Finish(params) {
	return axios({
		url: '/activity20231210/finish',
		method: 'post',
		data: params
	})
}

//宝箱开奖
export function activity20231210Draw(params) {
	return axios({
		url: '/activity20231210/draw',
		method: 'post',
		data: params
	})
}

//扭蛋机开奖
export function activity20231210EggRun(params) {
	return axios({
		url: '/activity20231210/egg_run',
		method: 'post',
		data: params
	})
}

//扭蛋机抽奖记录
export function activity20231210EggRecords(page) {
	return axios({
		url: '/activity20231210/egg_records',
		params: {
			page
		}
	})
}

//宝箱抽奖记录
export function activity20231210DrawRecords(page) {
	return axios({
		url: '/activity20231210/draw_records',
		params: {
			page
		}
	})
}

//充值记录
export function activity20231210RechargeRecords(page) {
	return axios({
		url: '/activity20231210/recharge_records',
		params: {
			page
		}
	})
}

//新人红包领取
export function newUserReward() {
	return axios({
		url: '/user/new_user_reward',
		method: 'post'
	})
}


//消息中心
export function userMessages(page) {
	return axios({
		url: '/user/messages',
		params: {
			page
		}
	})
}

//汰换记录
export function syntheRecords(page) {
	return axios({
		url: '/user/synthe_records',
		params: {
			page
		}
	})
}

//实名认证
export function realname(params) {
	return axios({
		url: '/user/realname',
		method: 'post',
		data: params
	})
}

//账单明细
export function beanLogs(page, type) {
	return axios({
		url: '/user/bean_logs',
		params: {
			page,
			type
		}
	})
}

//签到信息
export function signinInfo(params) {
	return axios({
		url: '/sign-in/info',
		method: 'get',
		params: params
	})
}

//签到信息
export function signinFinish(params) {
	return axios({
		url: '/sign-in/finish',
		method: 'post',
		data: params
	})
}













